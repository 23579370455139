import { Link } from '@gravity/link';
import type { HasContractsToApproveData } from '@monorepo/interfaces';
import { useRouter } from '@monorepo/router';
import { useSafeAreaInsets } from '@monorepo/unified-shell';
import { IsaacLogo } from '@srcTailwindUi/components/IsaacLogo';
import { Menu } from '@srcTailwindUi/components/Menu';
import { cn } from '@srcTailwindUi/lib/utils';
type NavBarProps = {
  className?: string;
  showMenu?: boolean;
  impersonateEnabled?: boolean;
  userName?: string;
  fidcData?: HasContractsToApproveData;
};
function NavBar({
  className,
  showMenu = true,
  impersonateEnabled,
  userName,
  fidcData
}: NavBarProps) {
  const router = useRouter();
  const {
    top
  } = useSafeAreaInsets();
  return <div className={cn('fixed left-0 top-0 z-50 flex w-full items-end justify-center bg-blue-600 px-6 py-4', className)} style={{
    height: `calc(3.5rem + ${top}px)`
  }} data-sentry-component="NavBar" data-sentry-source-file="index.tsx">
      <div className="flex w-full max-w-screen-sm items-center justify-between">
        <Link href="#" onClick={() => router.push('/home', {
        useWindowOpen: true
      })} underline="none" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <IsaacLogo data-sentry-element="IsaacLogo" data-sentry-source-file="index.tsx" />
        </Link>
        {showMenu && <div className="self-center">
            <Menu impersonateEnabled={impersonateEnabled} userName={userName} fidcData={fidcData} />
          </div>}
      </div>
    </div>;
}
export { NavBar, type NavBarProps };
import { cva } from 'class-variance-authority';
export const buttonVariants = cva('inline-flex items-center justify-center rounded-lg p-3 font-roboto text-base font-medium leading-4 tracking-wide transition-colors focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-blue-300 disabled:pointer-events-none disabled:text-gray-300', {
  variants: {
    variant: {
      primary: 'bg-blue-600 text-white active:bg-[#3746C1] disabled:bg-gray-50 md:hover:bg-blue-700',
      secondary: 'bg-white text-blue-600 active:bg-[#F7F8FD] disabled:text-gray-300 md:hover:bg-blue-100',
      lightGray: 'bg-gray-30 text-blue-600 active:bg-gray-30'
    },
    size: {
      default: 'h-12 px-4 py-2',
      sm: 'mt-6 h-12 w-[100px] py-2 text-sm',
      lg: 'mt-6 h-12 w-[300px] py-2',
      full: 'h-12 w-full'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default'
  }
});